document.addEventListener("turbolinks:load", function () {
  $input = $("[data-behavior='autocomplete']")

  var options = {
    getValue: "name",
    url: function (phrase) {
      return "/categories/search_inventory.json?search=" + phrase;
    },
    categories: [{
        listLocation: "inventories",
        header: "<hr><strong>Items result:</strong><hr>",
      },
      {
        listLocation: "categories",
        header: "<hr><strong>Categories result:</strong><hr>",
      }
    ],
    list: {
      onChooseEvent: function () {
        var url = $input.getSelectedItemData().url
        $input.val("")
        Turbolinks.visit(url)
        console.log(url)
      }
    }
  }

  $input.easyAutocomplete(options)

});
