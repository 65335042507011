
function add_to_cart(inventory, category, quantity, inventory_timestamp, note){
  //function for a add to cart item
  $.ajax({
    url: '/carts/add_cart',
    type: 'GET',
    dataType: 'script',
    data:{
      inventory_id: inventory,
      category_id: category,
      quantity: quantity,
      note: note,
      inventory_timestamp: inventory_timestamp
    },
    dataType: 'script',
    success: function (result) {
    
    },
    error: function (){
        window.alert("something wrong!");
    }
  });
}

function remove_item_to_cart(inventory){
  //function for a add to cart item
  $.ajax({
    url: '/carts/'+ inventory,
    type: 'DELETE',
    dataType: 'script',
    data:{
      id: inventory,
    },
    dataType: 'script',
    success: function (result) {
    
    },
    error: function (){
        window.alert("something wrong!");
    }
  });
}
// $(document).ready(function () {
//   // change the quantity of item and add to cart
//   $(document).on("click", ".btn-minus", function () {
//     var item_id = this.dataset.id
//     var type = "";
//     var addon_id ="";
//     var group_id ="";
//     if ($(this).data('type') != 'undefined'){
//       type = this.dataset.type;
//     }else{
//       type = ""
//     }
//     if ($(this).data(' addonid') != 'undefined'){
//       addon_id = this.dataset. addonid;
//     }else{
//       addon_id = ""
//     }
//     if ($(this).data('groupid') != 'undefined'){
//       group_id = this.dataset.groupid;
//     }else{
//       group_id = ""
//     }
//     var category_id = this.dataset.category_id

//     if (addon_id != ("" || undefined)){
//       var quantity = parseInt($('.'+ addon_id +"-addon-quantity-"+item_id).val());
//     }else if (group_id != ("" || undefined)){
//       var quantity = parseInt($('.'+ group_id +"-group-quantity-"+item_id).val());
//     }else{
//       var quantity = parseInt($('.quantity-'+item_id).val());
//     }
//     // var now = $(".add_item_quantity input").val();
//     if ($.isNumeric(quantity)) {
//       if (parseInt(quantity) - 0 > 1) {
//         quantity--;
        
//         if (addon_id != ("" || undefined)){
//           $('.'+ addon_id +"-addon-quantity-"+item_id).val(quantity);
//         }else if (group_id != ("" || undefined)){
//           $('.'+ group_id +"-group-quantity-"+item_id).val(quantity);
//         }else{
//           $('.quantity-'+item_id).val(quantity);
//         }
//         add_to_cart(item_id, category_id, quantity, type, addon_id, group_id);
//       }else{
//         window.alert('Item has minimum quantity 1')
//       }
     
//     }
    
//   })
//   $(document).on("click", ".btn-plus", function () {
//     var item_id = this.dataset.id;
//     var category_id = this.dataset.category_id;
//     var type = "";
//     var addon_id ="";
//     var group_id = "";
//     if ($(this).data('type') != 'undefined'){
//       type = this.dataset.type;
//     }else{
//       type = ""
//     }
//     if ($(this).data(' addonid') != 'undefined'){
//       addon_id = this.dataset. addonid;
//     }else{
//       addon_id = ""
//     }
//     if ($(this).data('groupid') != 'undefined'){
//       group_id = this.dataset.groupid;
//     }else{
//       group_id = ""
//     }

//     if (addon_id != ("" || undefined)){
//       var quantity = parseInt($('.'+ addon_id +"-addon-quantity-"+item_id).val());
//     }else if (group_id != ("" || undefined)){
//       var quantity = parseInt($('.'+ group_id +"-group-quantity-"+item_id).val());
//     }else{
//       var quantity = parseInt($('.quantity-'+item_id).val());
//     }

    
//     if ($.isNumeric(quantity)) {
//       // $(this).prev('input').val(parseInt(quntity) + 1);
//       quantity++;
//       if (addon_id != ("" || undefined)){
//         $('.'+ addon_id +"-addon-quantity-"+item_id).val(quantity);
//       }else if (group_id != ("" || undefined)){
//         $('.'+ group_id +"-group-quantity-"+item_id).val(quantity);
//       }else{
//         $('.quantity-'+item_id).val(parseInt(quantity));
//       }
//       add_to_cart(item_id, category_id, parseInt(quantity), type, addon_id, group_id);
//     }
    
//   })
//   //end the change quantity

//   //add the item from add button
//   $(document).on("click", ".add-item", function () {
//     var category = this.dataset.category;
//     var inventory = this.dataset.inventory;
    
    
//     var type = "";
//     var addon_id ="";
//     var group_id = "";
//     if ($(this).data('type') != 'undefined'){
//       type = this.dataset.type;
//     }else{
//       type = ""
//     }
//     if ($(this).data(' addonid') != 'undefined'){
//       addon_id = this.dataset. addonid;
//     }else{
//       addon_id = ""
//     }
//     if ($(this).data('groupid') != 'undefined'){
//       group_id = this.dataset.groupid;
//     }else{
//       group_id = ""
//     }
//     // var price = this.dataset.price;
//     var quantity = "1";
//     if (addon_id != ("" || undefined)){
//       $('.'+addon_id+'-addon_item_quantity-'+ inventory).removeClass('display-none');
//       $('.'+addon_id+'-addon-item-add-'+ inventory).addClass('display-none');
//       $('.'+addon_id+'-addon-quantity-'+ inventory).val(quantity);
//     }else if (group_id != ("" || undefined)){
//       $('.'+group_id+'-group_item_quantity-'+ inventory).removeClass('display-none');
//       $('.'+group_id+'-modifier-item-add-'+ inventory).addClass('display-none');
//       $('.'+group_id+'-group-quantity-'+ inventory).val(quantity);
//     }else{
//       $('.add_item_quantity-'+ inventory).removeClass('display-none');
//       $('.item-add-'+ inventory).addClass('display-none');
//       $('.quantity-'+ inventory).val(quantity);
//     }
//     add_to_cart(inventory, category, quantity, type, addon_id, group_id)
//   });
//   //end the add item from add button
// });
//change the inventory quantity
$(document).on("click", ".btn-inventory-plus", function () {
  var item_id = this.dataset.id;
  if(parseInt($('.inventory-quantity-' + item_id).val()) >= parseInt($('.available_quantity_' + item_id).val())) {
    $('.btn-inventory-plus').addClass('disabled-btn-group-qantity');
    return window.alert('This Item has Only ' + $('.available_quantity_' + item_id).val() + ' Quantity Left.')
  }
  else{
    $('.btn-inventory-plus').removeClass('disabled-btn-group-qantity');
  }
  var quantity = $('.inventory-quantity-'+item_id).val();
  if ($.isNumeric(quantity)) {
    // $(this).prev('input').val(parseInt(quntity) + 1);
    quantity++;
    $('.inventory-quantity-'+item_id).val(parseInt(quantity));
    if(quantity >= parseInt($('.available_quantity_' + item_id).val())) {
      $('.btn-inventory-plus').addClass('disabled-btn-group-qantity');
    }
    else{
      $('.btn-inventory-plus').removeClass('disabled-btn-group-qantity');
    }
  }
});

$(document).on("click", ".btn-inventory-minus", function () {
  var item_id = this.dataset.id;
  var quantity = $('.inventory-quantity-'+item_id).val();
  if ($.isNumeric(quantity)) {
    if (parseInt(quantity) - 0 > 0) {
      quantity--;
      if (quantity == 0){
        $('.inventory-quantity-'+item_id).val(parseInt(quantity));
        // remove_item_to_cart(item_id);
      }else{
        $('.inventory-quantity-'+item_id).val(parseInt(quantity));
        // remove_item_to_cart(item_id);
      }

      if(quantity < parseInt($('.available_quantity_' + item_id).val())) {
        $('.btn-inventory-plus').removeClass('disabled-btn-group-qantity');
      }

    }else{
      window.alert('Item has minimum quantity 1');
    }
  }
});
//end the inventory quantity

//change the adddon inventory quantity
$(document).on("click", ".btn-addon-plus", function () {
  var inventory = this.dataset.inventory;
  var item_id = this.dataset.id;
  var quantity = $('.'+inventory+'-addon-quantity-'+item_id).val();
  if ($.isNumeric(quantity)) {
    // $(this).prev('input').val(parseInt(quntity) + 1);
    quantity++;
    $('.'+inventory+'-addon-quantity-'+item_id).val(parseInt(quantity));
  }
});

$(document).on("click", ".btn-addon-minus", function () {
  var inventory = this.dataset.inventory;
  var item_id = this.dataset.id;
  var quantity = $('.'+inventory+'-addon-quantity-'+item_id).val();
  if ($.isNumeric(quantity)) {
    if (parseInt(quantity) - 0 > 0) {
      quantity--;
      if (quantity == 0){
        $('.'+inventory+'-addon-quantity-'+item_id).val(parseInt(quantity));
        remove_item_to_cart(item_id);
      }else{
        $('.'+inventory+'-addon-quantity-'+item_id).val(parseInt(quantity));
      }
    }else{
      window.alert('Item has minimum quantity 1')
    }
  }
});
//end the inventory quantity
// $(document).on('click','.add-item', function(){
//   item_id = this.dataset.id
//   addon_id = this.dataset.addonid;
// });
//inventory quantity and add item in cart

$(document).on("click", ".add_inventory", function () {
  var item_id = this.dataset.id;
  var category_id = this.dataset.category_id;
  var quantity = $('.inventory_quantity_'+item_id).val();
  var note = $('.inventory_note_'+item_id).val();
  if ($.isNumeric(quantity)) {
    $('.inventory_quantity_'+item_id).val(parseInt(quantity));
    add_to_cart(item_id, category_id, quantity, note);
  }
});

plus_op = ( element, cart ) => {
  var item_id = element.dataset.id;
  var category_id = element.dataset.category_id;
  var quantity = $('.inventory_quantity_'+item_id).val();
  let price = element.dataset.price;
  let cart_item_id = element.dataset.cartId;
  let inventory_timestamp = element.dataset.timestamp;
  if ($.isNumeric(quantity)) {
    // $(this).prev('input').val(parseInt(quntity) + 1);
    quantity++;
    $('.inventory_quantity_'+item_id).val(parseInt(quantity));
    $('#item_price_pop_'+item_id).text((parseFloat(price) * parseInt(quantity) || 0).toFixed(2));
    $('#item_quantity_pop_'+item_id).text(parseInt(quantity));
    $('.addItemToOrder_'+item_id).prop('disabled', false);
    if (cart) add_to_cart(cart_item_id, category_id, quantity, inventory_timestamp);
  }
}

minus_op = ( element, cart ) => {
  var item_id = element.dataset.id;
  var category_id = element.dataset.category_id;
  var quantity = $('.inventory_quantity_'+item_id).val();
  let price = element.dataset.price;
  let cart_item_id = element.dataset.cartId;
  let inventory_timestamp = element.dataset.timestamp;
  if ($.isNumeric(quantity)) {
    if (parseInt(quantity) - 0 > 0) {
      quantity--;
      $('.inventory_quantity_'+item_id).val(parseInt(quantity));
      $('#item_price_pop_'+item_id).text((parseFloat(price) * parseInt(quantity) || 0).toFixed(2));
      $('#item_quantity_pop_'+item_id).text(parseInt(quantity));
      if (quantity == 0){
        $('.addItemToOrder_'+item_id).prop('disabled', true);
        if (cart) remove_item_to_cart(cart_item_id);
      }else{
        $('.addItemToOrder_'+item_id).prop('disabled', false);
        if (cart) add_to_cart(cart_item_id, category_id, quantity, inventory_timestamp);
      }
    } else {
      window.alert('Item has minimum quantity 1')
    }
  }
}

$(document).on("click", ".pop_inventory_plus", function () { // popup plus button
  plus_op( this );
});

$(document).on("click", ".inventory_plus", function () { // cart plus button
  plus_op( this, true );
});

$(document).on("click", ".pop_inventory_minus", function () { // popup minus button
  minus_op( this );
});

$(document).on("click", ".inventory_minus", function () { // cart minus button
  minus_op( this, true );
});
// end the inventory item add in cart.