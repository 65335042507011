window.Parsley.addValidator('fileextension', (function (value, requirement) {
  var allowedExtensions, fileExtension;
  fileExtension = value.split('.').pop();
  allowedExtensions = requirement.split(',');
  return $.inArray(fileExtension, allowedExtensions) > -1;
}), 32).addMessage('en', 'fileextension', 'Please enter a valid file');

window.Parsley.addValidator('filesize', (function (value, requirement) {
  var input = $('input[type="file"]');
  var maxsize = requirement;
  var max_bytes = maxsize * 1000, file = input[0].files[0];
  return file.size <= max_bytes;
  var allowedExtensions, fileExtension;
}), 32).addMessage('en', 'filesize', 'Please upload under 1MB file');
