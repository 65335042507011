window.appropriate_time = (time) => {
  var hour = parseInt(time.split(":")[0], 10);
  var split_time = time.split(":")[1];
  var minute = parseInt(split_time.split(" ")[0], 10);
  var am_pm = split_time.split(" ")[1];
  if (minute > 0 && minute <= 30) {
    minute = 30;
  } else if (minute > 30) {
    if (hour == 11) {
      if (am_pm == "PM") {
        am_pm = "AM";
      } else {
        am_pm = "PM";
      }
    }
    if (hour == 12) {
      hour = 1;
    } else {
      hour += 1;
    }
    minute = 0;
  }
  return final_time = hour + ':' + ("00" + minute).substr(-2) + ' ' + am_pm
}

url_rewriter = () => {
  $('.category_link').on('click', function(){
    let arr = $(this).attr('href').split("/");
    let category_id = arr[arr.length - 1];
    window.history.replaceState("", "", "/categories?category_id=" + category_id);
  })
}
