// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")
require("custom")
require("add_cart")
require("parsleyjs")
require('jquery.blockUI.min.js')
require("custome_validator")
require('easy-autocomplete')
ParsleyConfig.autoBind = false;
// require('jquery.min')
// require('bootstrap.min')
// // require("owl.carousel.js")
// require("popper.min")

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
import "jquery.min.js"
import "bootstrap.min.js"
import "popper.js"
import "@fortawesome/fontawesome-free/js/all";
import "@fortawesome/fontawesome-free/css/all.css";
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel';
import 'search_inventory';
// import "parsley_load";
// import 'parsleyjs';
$(document).on('turbolinks:load', function() {
  $('[data-parsley-validate]').each(function() {
    new Parsley.Factory(this)
  });
	$(".alert-header-msg").fadeOut(8000);
});
